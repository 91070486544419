import React from 'react';
import ColumnNewThreeCol from '../components/ColumnNewThreeCol';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
`;


const explore= () => (
<div>
<GlobalStyles/>
  <section className='container'>
        <div className='row'>
        <div className="spacer-double"></div>

          <div className='col-md-3'>

          <div className="item_filter_group">
              <h4>Select Categories</h4>
              <div className="de_form">
                  <div className="de_checkbox">
                      <input id="check_cat_1" name="check_cat_1" type="checkbox" value="check_cat_1"/>
                      <label htmlFor="check_cat_1">Sponsors</label>
                  </div>

                  <div className="de_checkbox">
                      <input id="check_cat_2" name="check_cat_2" type="checkbox" value="check_cat_2"/>
                      <label htmlFor="check_cat_2">Value</label>
                  </div>

                  <div className="de_checkbox">
                      <input id="check_cat_3" name="check_cat_3" type="checkbox" value="check_cat_3"/>
                      <label htmlFor="check_cat_3">Rivals</label>
                  </div>

        

                  <div className="de_checkbox">
                      <input id="check_cat_7" name="check_cat_7" type="checkbox" value="check_cat_7"/>
                      <label htmlFor="check_cat_7">Sports</label>
                  </div>

               

              </div>
          </div>

          <div className="item_filter_group">
              <h4>Status</h4>
              <div className="de_form">
                  <div className="de_checkbox">
                      <input id="buy" name="buy" type="checkbox" value="buy"/>
                      <label htmlFor="buy">Open</label>
                  </div>

                  <div className="de_checkbox">
                      <input id="onauction" name="onauction" type="checkbox" value="onauction"/>
                      <label htmlFor="onauction">Booked</label>
                  </div>

                  <div className="de_checkbox">
                      <input id="offers" name="offers" type="checkbox" value="offers"/>
                      <label htmlFor="offers">Closed</label>
                  </div>

              </div>
          </div>

          <div className="item_filter_group">
              <h4>Champion Type</h4>
              <div className="de_form">
                  <div className="de_checkbox">
                      <input id="sitems" name="sitems" type="checkbox" value="sitems"/>
                      <label htmlFor="sitems">Top Rank</label>
                  </div>

                  <div className="de_checkbox">
                      <input id="bundles" name="bundles" type="checkbox" value="bundles"/>
                      <label htmlFor="bundles">Most Liked</label>
                  </div>

              </div>
          </div>

          <div className="item_filter_group">
              <h4>Club</h4>
              <div className="de_form">
                  <div className="de_checkbox">
                      <input id="abstract" name="abstract" type="checkbox" value="abstract"/>
                      <label htmlFor="abstract">Real Madrid</label>
                  </div>

                  <div className="de_checkbox">
                      <input id="paterns" name="paterns" type="checkbox" value="paterns"/>
                      <label htmlFor="paterns">Barcelona</label>
                  </div>

                  <div className="de_checkbox">
                      <input id="skecth" name="skecth" type="checkbox" value="skecth"/>
                      <label htmlFor="skecth">Manchester United</label>
                  </div>

                  <div className="de_checkbox">
                      <input id="cartoon" name="cartoon" type="checkbox" value="cartoon"/>
                      <label htmlFor="cartoon">Juventus</label>
                  </div>

                  <div className="de_checkbox">
                      <input id="virtualand" name="virtualand" type="checkbox" value="virtualand"/>
                      <label htmlFor="virtualand">Cheslea</label>
                  </div>

                  <div className="de_checkbox">
                      <input id="pappercut" name="pappercut" type="checkbox" value="pappercut"/>
                      <label htmlFor="pappercut">Paris-Saint-German</label>
                  </div>

              </div>
          </div>

          </div>

          <div className="col-md-9">
            <ColumnNewThreeCol/>
          </div>
        </div>
      </section>


  <Footer />
</div>

);
export default explore;